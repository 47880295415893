<!-- Start Navbar -->
<nav class="navbar navbar-expand-lg fixed-top custom-nav sticky">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand brand-logo mr-4" href="index.html">
      <img src="../assets/images/logo.png" class="img-fluid logo-light" alt="">
      <img src="../assets/images/dark-logo.png" class="img-fluid logo-dark" alt="">
    </a>
    <div class="navbar-collapse collapse justify-content-end" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item active">
          <a href="#home" class="nav-link">Home</a>
        </li>
        <li class="nav-item">
          <a href="#aboutus" class="nav-link">About Us</a>
        </li>
        <li class="nav-item">
          <a href="#howitworks" class="nav-link">How it Work</a>
        </li>
        <li class="nav-item">
          <a href="#price" class="nav-link">Price</a>
        </li>
        <li class="nav-item">
          <a href="#faq" class="nav-link">Faq's</a>
        </li>
        <li class="nav-item">
          <a href="#contact" class="nav-link">Contact Us</a>
        </li>
      </ul>
      <div class="menu-close-btn"><i class="mdi mdi-close-circle-outline"></i></div>
    </div>
    <div class="header_btn">
      <button class="navbar-toggler ml-2 p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i class="mdi mdi-menu"></i>
      </button>
    </div>
  </div>
</nav>
<!-- End Navbar -->

<router-outlet></router-outlet>
