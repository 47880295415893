<!-- Start Footer -->
<footer class="footer bg-gradient">
  <div class="bg-jumbstron-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <ul class="footer_menu_list list-unstyled mb-0">
          <li><a href="#">Home</a></li>
          <li><a href="#">About Us</a></li>
          <li><a href="#">App Features</a></li>
          <li><a href="#">How It Work</a></li>
          <li><a href="#">Price</a></li>
          <li><a href="#">FAQ's</a></li>
          <li><a href="#">Contact Us</a></li>
        </ul>
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-center">
          <a class="social-icons" href="#"><i class="mdi mdi-facebook-box"></i></a>
          <a class="social-icons" href="#"><i class="mdi mdi-twitter"></i></a>
          <a class="social-icons" href="#"><i class="mdi mdi-dribbble"></i></a>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="copyrights">
          <p class="mb-0 text-center">2020 &copy; <span class="text-white font-weight-bold">Yuktto.</span> Design by Vrishank Softtech.</p>
        </div>
      </div>

    </div>
  </div>
</footer>
<!-- End Footer -->
<!-- Back To Top -->
<a href="#" class="back_top"> <i class="mdi mdi-chevron-up"></i></a>

<router-outlet></router-outlet>
