
<!-- Toolbar -->
<div class="toolbar" role="banner">
  <!-- Start Home -->
  <section class="home-section" id="home">
    <div class="home-bg d-flex align-items-center justify-content-center">
      <div class="welcome-text-area">
        <div class="area-bg"></div>
        <div class="welcome-area">
          <div class="container">
            <div class="row align-items-center justify-content-between">
              <div class="col-md-7 col-lg-6">
                <div class="banner-content-left text-white">
                  <h1 class="text-white">Learn Code, Build App </h1>
                  <p class="lead">
                    simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                  </p>
                  <a href="#contact" class="btn btn-white animated-btn">Contact with us</a>
                </div>
              </div>
              <div class="col-md-5 col-lg-6">
                <div class="banner-img-right text-center">
                  <img src="../assets/images/slider-img/hero-banner.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shape-bottom">
      <img src="../assets/images/slider-img/bg-wave.svg" class="img-fluid" alt="shape">
    </div>
  </section>
  <!-- End Home  -->
  <!-- Start About-Us -->
  <section class="section" id="aboutus">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="text-center title">
            <h2 class="text-center title-txt theme-text">We Can Help You Succeed.</h2>
            <p class="text-muted">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit sed consequuntur as sequi nesciunt.</p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-4">
          <div class="help-box">
            <div class="d-flex justify-content-center"><i class="mdi mdi-chat-processing-outline theme-text"></i></div>
            <div class="box-title theme-text">Best Advices</div>
            <div class="box-description">simply dummy text of the printing and typesetting industry.</div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="help-box">
            <div class="d-flex justify-content-center"><i class="mdi mdi-thumb-up-outline theme-text"></i></div>
            <div class="box-title theme-text">24/7 Support</div>
            <div class="box-description">simply dummy text of the printing and typesetting industry.</div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="help-box">
            <div class="d-flex justify-content-center"><i class="mdi mdi-chart-arc theme-text"></i></div>
            <div class="box-title theme-text">Best Advices</div>
            <div class="box-description">simply dummy text of the printing and typesetting industry.</div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-12 col-md-6 ">
          <div class="row">
            <div class="col-sm-6">
              <div class="feature-box">
                <i class="mdi mdi-globe-model feature-ico theme-text"></i>
                <h5 class="feature-title theme-text font-weight-bold">Business Goal</h5>
                <p>Nemo enim ipsam voluptatem quia voluptas.</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="feature-box">
                <i class="mdi mdi-format-color-fill feature-ico theme-text"></i>
                <h5 class="feature-title theme-text font-weight-bold">Awesome Design</h5>
                <p>Nemo enim ipsam voluptatem quia voluptas.</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="feature-box">
                <i class="mdi mdi-headphones feature-ico theme-text"></i>
                <h5 class="feature-title theme-text font-weight-bold">Awesome Support</h5>
                <p>Nemo enim ipsam voluptatem quia voluptas.</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="feature-box">
                <i class="mdi mdi-chart-line feature-ico theme-text"></i>
                <h5 class="feature-title theme-text font-weight-bold">Dynamic Growth</h5>
                <p>Nemo enim ipsam voluptatem quia voluptas.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="about-text-block">
            <h6 class="theme-text font-weight-bold">ABOUT US</h6>
            <h2>Somethings about Us.</h2>
            <p class="text">Thanks to the commonwealth of Virginia, no matter where you live in the U.S., your documents can now be notarized online by a trusted notary.Thanks to the commonwealth of Virginia, no matter where you live in the U.S., your documents can now be notarized online by a trusted notary.</p>
            <a href="#contact" class="btn btn-theme animated-btn">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End About-Us -->
  <!-- Start Counter-->
  <section class="parallax parallax-box section">
    <div id="counter">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-6">
            <div class="counter-box text-center">
              <div class="counter-ico"><i class="mdi mdi-rocket"></i></div>
              <h3 id="number1" class="number counter-value" data-count="12">0</h3>
              <span></span>
              <p>Projects done</p>
            </div>
          </div>
          <div class="col-md-3 col-6">
            <div class="counter-box text-center">
              <div class="counter-ico"><i class="mdi mdi-account-group"></i></div>
              <h3 id="number2" class="number counter-value" data-count="55">0</h3>
              <span></span>
              <p>Happy clients</p>
            </div>
          </div>
          <div class="col-md-3 col-6">
            <div class="counter-box text-center">
              <div class="counter-ico"><i class="mdi mdi-coffee"></i></div>
              <h3 id="number3" class="number counter-value" data-count="360">0</h3>
              <span></span>
              <p>Awards</p>
            </div>
          </div>
          <div class="col-md-3 col-6">
            <div class="counter-box text-center">
              <div class="counter-ico"><i class="mdi mdi-trophy"></i></div>
              <h3 id="number3" class="number counter-value" data-count="10">0</h3>
              <span></span>
              <p>Cups of coffee</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Counter-->
  <!-- Start How-it-Work -->
  <section class="section" id="howitworks">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="text-center title">
            <h2 class="text-center title-txt theme-text">How Does It Works?</h2>
            <p class="text-muted">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit sed consequuntur as sequi nesciunt.</p>
          </div>
        </div>
      </div>
      <div class="work-process-wrap">
        <div class="row align-items-center">
          <div class="col-lg-3 col-sm-6">
            <div class="single-work-process">
              <div class="work-icon-wrap color-1-bg rounded">
                <i class="mdi mdi-globe-model icon-md color-1"></i>
                <span class="process-step bg-white shadow-sm">1</span>
              </div>
              <div class="work-process-content mt-4">
                <h5>Generate Ideas</h5>
                <p>Compellingly harness reliable methodologies and orthogonal web services. </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-work-process">
              <div class="work-icon-wrap color-2-bg rounded">
                <i class="mdi mdi-desktop-mac-dashboard icon-md color-2"></i>
                <span class="process-step bg-white shadow-sm">2</span>
              </div>
              <div class="work-process-content mt-4">
                <h5>Create Design</h5>
                <p>Compellingly harness reliable methodologies and orthogonal web services. </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-work-process">
              <div class="work-icon-wrap color-3-bg rounded">
                <i class="mdi mdi-file-code-outline icon-md color-3"></i>
                <span class="process-step bg-white shadow-sm">3</span>
              </div>
              <div class="work-process-content mt-4">
                <h5>Development</h5>
                <p>Compellingly harness reliable methodologies and orthogonal web services. </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="single-work-process">
              <div class="work-icon-wrap color-4-bg rounded">
                <i class="mdi mdi-rocket icon-md color-4"></i>
                <span class="process-step bg-white shadow-sm">4</span>
              </div>
              <div class="work-process-content mt-4">
                <h5>Launch Project</h5>
                <p>Compellingly harness reliable methodologies and orthogonal web services. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End How-it-Work -->
  <!-- Start Jumboston -->
  <section class="section bg-gradient">
    <div class="container">
      <div class="bg-jumbstron-overlay"></div>
      <div class="row justify-content-center z-index">
        <div class="col-lg-7">
          <div class="text-center title">
            <h2 class="text-center title-txt text-white">Become a part of Our bussiness community today</h2>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center z-index">
        <a href="#contact" class="btn btn-white animated-btn mt-4">Contact with us</a>
      </div>
    </div>
  </section>
  <!-- End Jumboston -->
  <!-- Start Testimonial -->
  <section class="section client-Testimonials" id="client">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="text-center title">
            <h2 class="text-center title-txt theme-text">Client's Says</h2>
            <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="owl-carousel owl-theme client-slider">
            <div class="item">
              <div class="client">
                <div class="client-image">
                  <img src="../assets/images/testi/client01.jpg" class="img-fluid" alt="">
                </div>
                <h4 class="client-name text-center">
                  Jack Jordan
                </h4>
                <h5 class="designation text-center">Lead Developer</h5>
                <p class="client-say">
                  Contrary to popular belief, this is not simply random
                  text. It has roots in a piece of classical Latin literature from 45 BC.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="client">
                <div class="client-image">
                  <img src="../assets/images/testi/client01.jpg" class="img-fluid" alt="">
                </div>
                <h4 class="client-name text-center">
                  Jack Jordan
                </h4>
                <h5 class="designation text-center">Lead Developer</h5>
                <p class="client-say">
                  Contrary to popular belief, this is not simply random
                  text. It has roots in a piece of classical Latin literature from 45 BC.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="client">
                <div class="client-image">
                  <img src="../assets/images/testi/client01.jpg" class="img-fluid" alt="">
                </div>
                <h4 class="client-name text-center">
                  Jack Jordan
                </h4>
                <h5 class="designation text-center">Lead Developer</h5>
                <p class="client-say">
                  Contrary to popular belief, this is not simply random
                  text. It has roots in a piece of classical Latin literature from 45 BC.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="client">
                <div class="client-image">
                  <img src="../assets/images/testi/client01.jpg" class="img-fluid" alt="">
                </div>
                <h4 class="client-name text-center">
                  Jack Jordan
                </h4>
                <h5 class="designation text-center">Lead Developer</h5>
                <p class="client-say">
                  Contrary to popular belief, this is not simply random
                  text. It has roots in a piece of classical Latin literature from 45 BC.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="client">
                <div class="client-image">
                  <img src="../assets/images/testi/client01.jpg" class="img-fluid" alt="">
                </div>
                <h4 class="client-name text-center">
                  Jack Jordan
                </h4>
                <h5 class="designation text-center">Lead Developer</h5>
                <p class="client-say">
                  Contrary to popular belief, this is not simply random
                  text. It has roots in a piece of classical Latin literature from 45 BC.
                </p>
              </div>
            </div>
            <div class="item">
              <div class="client">
                <div class="client-image">
                  <img src="../assets/images/testi/client01.jpg" class="img-fluid" alt="">
                </div>
                <h4 class="client-name text-center">
                  Jack Jordan
                </h4>
                <h5 class="designation text-center">Lead Developer</h5>
                <p class="client-say">
                  Contrary to popular belief, this is not simply random
                  text. It has roots in a piece of classical Latin literature from 45 BC.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Testimonial -->
  <!-- Start Price -->
  <section class="section bg-light" id="price">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="text-center title">
            <h2 class="text-center title-txt theme-text">Best Pricing Plans</h2>
            <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="package-box">
                <div class="p-box-inner">
                  <div class="hover-line">
                    <div class="plan-icons">
                      <i class="mdi mdi-rocket"></i>
                    </div>
                    <h2><em>$</em>10.00</h2>
                    <ul class="package-list-data">
                      <li>2gb Hosting</li>
                      <li>10 Custom Domain</li>
                      <li>SSD Hosting</li>
                    </ul>

                    <div class="try-now-btn">
                      <a href="#" class="btn btn-theme-border animated-btn">Try Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="package-box">
                <div class="ribbon">
                  <p class="mb-0 text-white">-20%</p>
                </div>
                <div class="p-box-inner">
                  <div class="hover-line">
                    <div class="plan-icons">
                      <i class="mdi mdi-rocket"></i>
                    </div>
                    <h2><em>$</em>20.00</h2>
                    <ul class="package-list-data">
                      <li>2gb Hosting</li>
                      <li>10 Custom Domain</li>
                      <li>SSD Hosting</li>
                    </ul>
                    <div class="try-now-btn">
                      <a href="#" class="btn btn-theme-border animated-btn">Try Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 ">
              <div class="package-box">
                <div class="ribbon">
                  <p class="mb-0 text-white">-50%</p>
                </div>
                <div class="p-box-inner">
                  <div class="hover-line">
                    <div class="plan-icons">
                      <i class="mdi mdi-rocket"></i>
                    </div>
                    <h2><em>$</em>30.00</h2>
                    <ul class="package-list-data">
                      <li>2gb Hosting</li>
                      <li>10 Custom Domain</li>
                      <li>SSD Hosting</li>
                    </ul>
                    <div class="try-now-btn">
                      <a href="#" class="btn btn-theme-border animated-btn">Try Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Price -->
  <!-- Start FAQ's -->
  <section id="faq" class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="text-center title">
            <h2 class="text-center title-txt theme-text">Frequently Asked</h2>
            <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-8">
          <div id="accordion" class="faq-accordion-panel">
            <div class="question-card">
              <div class="card-header p-0" id="headingOne">
                <h4 class="mb-0">
                  <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span>Made With Love</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                </h4>
              </div>
              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                  <p>
                    this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.
                  </p>
                </div>
              </div>
            </div>
            <div class="question-card">
              <div class="card-header p-0" id="headingTwo">
                <h4 class="mb-0">
                  <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span>Free of Use</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                </h4>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                  <p>
                    this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.
                  </p>
                </div>
              </div>
            </div>
            <div class="question-card">
              <div class="card-header p-0" id="headingThree">
                <h4 class="mb-0">
                  <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span>Fully Support Avaliable</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                </h4>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                  <p>
                    this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.
                  </p>
                </div>
              </div>
            </div>
            <div class="question-card">
              <div class="card-header p-0" id="headingFour">
                <h4 class="mb-0">
                  <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span>Flat and Modern Ui & Ux</span><span><i class="mdi mdi-menu-down caret-icon"></i></span></button>
                </h4>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                <div class="card-body">
                  <p>
                    this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End FAQ's -->
  <!-- Start Contact -->
  <section class="section bg-light" id="contact">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="text-center title">
            <h2 class="text-center title-txt theme-text">Get In Touch</h2>
            <p class="section_subtitle mx-auto">this dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. this dolor sit amet.</p>
          </div>
        </div>
      </div>
      <form>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="contact_form">
              <div class="form-group">
                <input name="name" id="name" type="text" class="form-control" placeholder="Your name..." required>
              </div>
              <div class="form-group">
                <input name="email" id="email" type="email" class="form-control" placeholder="Your email..." required>
              </div>
              <div class="form-group">
                <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Your message..." required></textarea>
              </div>
              <div class="d-flex justify-content-start">
                <button type="submit" class="btn btn-theme animated-btn send-btn">Send Message</button>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <img src="../assets/images/contact/contact-img.png" class="img-fluid contact-img" alt="" />
          </div>
        </div>
      </form>
    </div>
  </section>
  <!-- End Contact -->
</div>
<router-outlet></router-outlet>
