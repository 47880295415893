import { Component , OnInit } from '@angular/core';
import * as $ from 'jquery';


@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
})
export class AppFooterComponent implements OnInit {
  ngOnInit() {
  }
}
